<template>
  <t-card class="t-search-result-card">
    <header class="t-search-result-card__header">
      <div class="t-search-result-card__username">
        <t-checkbox
          v-model="valueProxy"
          class="t-search-result-card__checkbox"
          :native-value="valueProxy"
          :disabled="result.pinged"
        >
          <h5>{{ result.applicant.user.fullname }}</h5>
        </t-checkbox>
      </div>
      <PingedState
        :state="result.pinged"
        :positive-label="$t('fields.pinged')"
        :negative-label="$t('fields.not_pinged')"
      />
    </header>
    <label class="t-search-result-card__label">
      {{ $t('fields.recommended_company') }}
    </label>
    <div class="t-search-result-card__value">
      {{ result.applicant.organization_name }}
    </div>
    <label class="t-search-result-card__label">
      {{ $t('fields.applied_for') }}
    </label>
    <div class="t-search-result-card__value">
      {{ result.applicant.occupation.name }}
    </div>
    <footer class="t-search-result-card__footer">
      <div>
        <t-icon
          class="t-search-result-card__icon"
          icon="map-marker-alt"
        />
        {{ result.distance }} {{ $t('global.units.km') }}
      </div>
      <div>{{ result.applicant.graduation_type.translation }}</div>
    </footer>
  </t-card>
</template>

<script>
import Vue from 'vue';
import PingedState from '~/components/StateTags/PingedState';

export default Vue.extend({
  components: {
    PingedState,
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },

    result: {
      type: Object,
      required: true,
    },
  },

  computed: {
    valueProxy: {
      get() {
        return this.value.includes(this.result.applicant.id);
      },

      set(val) {
        const filtered = this.value.filter(e => e !== this.result.applicant.id);
        const result = val
          ? [...filtered, this.result.applicant.id]
          : filtered;
        this.$emit('input', result);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.t-search-result-card {
  font-size: 12px;

  &__username {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  &__label {
    display: block;
    font-size: 10px;
    color: var(--grey-color);
    margin: 0 0 .4rem 0;
  }

  &__value {
    margin: 0 0 .7rem 0;
    font-weight: bold;
  }

  &__header,
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header {
    margin-bottom: 1.2rem;
  }

  &__footer {
    margin-top: 1.2rem;
    color: var(--grey-color);
  }

  &__icon {
    margin-right: .2rem;
  }
}
</style>
