<template>
  <section class="t-search-results-list">
    <t-grid>
      <t-grid-cell
        v-for="result in results"
        :key="result.applicant.id"
        :size="4"
        class="t-search-results-list__item"
      >
        <t-search-result-card
          v-model="valueProxy"
          :result="result"
        />
      </t-grid-cell>
    </t-grid>
  </section>
</template>

<script>
import Vue from 'vue';
import TSearchResultCard from './TSearchResultCard';

export default Vue.extend({
  components: {
    TSearchResultCard,
  },

  props: {
    results: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: true,
    },
  },

  computed: {
    valueProxy: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.t-search-results-list {
  &__item[data-cell-size="4"] {
    flex: 0 0 calc(33.3333333333% - (2rem * 2 /3));
  }
}
</style>
