<template>
  <t-tag :type="type" soft>
    {{ label }}
  </t-tag>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      required: true,
    },

    positiveLabel: {
      type: String,
      required: false,
      default: () => '',
    },

    negativeLabel: {
      type: String,
      required: false,
      default: () => '',
    },
  },

  computed: {
    label() {
      return this.state
        ? this.positiveLabel || this.$t('fields.yes')
        : this.negativeLabel || this.$t('fields.no');
    },

    type() {
      return this.state ? 'warning' : 'success';
    },
  },
};
</script>
